import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

import AccordionSection from "./accordion-section"

class Accordion extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
  }

  constructor(props) {
    super(props)

    const openSections = {}

    this.state = { openSections }
  }

  onClick = label => {
    const {
      state: { openSections },
    } = this

    const isOpen = !!openSections[label]

    this.setState({
      openSections: {
        [label]: !isOpen,
      },
    })
  }

  render() {
    const {
      onClick,
      props: { children },
      state: { openSections },
    } = this

    const AccordionContainer = styled.div`
      width: 100%;
      max-width: 50rem;
      margin: 0 auto;
    `

    return (
      <AccordionContainer>
        {children.map(child => (
          <AccordionSection
            isOpen={!!openSections[child.props.label]}
            label={child.props.label}
            onClick={onClick}
          >
            {child.props.children}
          </AccordionSection>
        ))}
      </AccordionContainer>
    )
  }
}

export default Accordion
