import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

const WhimsyIntro = () => {
  const data = useStaticQuery(graphql`
    query {
      craft {
        whimsykbd: entries(section: "whimsy") {
          ... on Craft_whimsy_whimsy_Entry {
            introduction
          }
        }
      }
    }
  `)

  const WhimsyIntro = styled.section`
    max-width: 58rem;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @media (max-width: 46rem) {
      padding-top: 2rem;
      padding-bottom: 2.5rem;
    }

    p {
      margin-bottom: 0;

      &:first-of-type {
        font-size: 1.4rem;
        line-height: 2.1rem;
        margin-bottom: 1.5rem;

        @media (max-width: 46rem) {
          font-size: 1.2rem;
          line-height: 1.8rem;
        }
      }

      @media (max-width: 46rem) {
        text-align: center;
      }
    }
  `

  return (
    <WhimsyIntro>
      <div
        dangerouslySetInnerHTML={{
          __html: data.craft.whimsykbd[0].introduction,
        }}
      />
    </WhimsyIntro>
  )
}

export default WhimsyIntro
