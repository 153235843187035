import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import Img from "gatsby-image"

const WhimsyRenders = () => {
  const data = useStaticQuery(graphql`
    query {
      craft {
        whimsykbd: entries(section: "whimsy") {
          ... on Craft_whimsy_whimsy_Entry {
            renders: whimsyRenders {
              ... on Craft_awsBucket_Asset {
                url
                title
              }
            }
            rImageFile {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

  const renderImages = data.craft.whimsykbd[0].rImageFile

  const WhimsyRenders = styled.section`
    max-width: 98rem;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(12, 1fr);
    gap: 3rem;

    @media (max-width: 46rem) {
      max-width: 100vw;
      padding-left: 0;
      padding-right: 0;
      gap: 1rem;
    }
  `

  const SingleRender = styled.div`
    grid-column-end: span 12;

    img {
      position: relative !important;

      @media (max-width: 46rem) {
        height: 17.5rem !important;
      }
    }
  `

  return (
    <WhimsyRenders>
      {renderImages.map((render, i) => (
        <SingleRender key={i}>
          <Img
            fluid={render.childImageSharp.fluid}
            alt="Whimsy"
            title="Whimsy"
          />
        </SingleRender>
      ))}
    </WhimsyRenders>
  )
}

export default WhimsyRenders
