import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

const WhimsyThanks = () => {
  const data = useStaticQuery(graphql`
    query {
      craft {
        whimsykbd: entries(section: "whimsy") {
          ... on Craft_whimsy_whimsy_Entry {
            whimsyThanks
          }
        }
      }
    }
  `)

  const WhimsyThanks = styled.section`
    max-width: 58rem;
    padding-top: 6rem;
    padding-bottom: 6rem;

    h5 {
      font-size: 1.9rem;
      margin: 0 0 2rem 0;
      padding: 0;
      text-align: center;
      width: 100%;
      position: relative;

      &:after {
        content: "";
        width: 2rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.75rem;
        height: 0.22rem;
        background-color: ${props => props.theme.primaryYellow};
      }
    }

    p {
      font-size: 0.9rem;
      text-align: center;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  `

  return (
    <WhimsyThanks>
      <h5>Thanks</h5>
      <div
        dangerouslySetInnerHTML={{
          __html: data.craft.whimsykbd[0].whimsyThanks,
        }}
      />
    </WhimsyThanks>
  )
}

export default WhimsyThanks
