import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const WhimsyHero = () => {
  const data = useStaticQuery(graphql`
    query {
      craft {
        whimsykbd: entries(section: "whimsy") {
          ... on Craft_whimsy_whimsy_Entry {
            hero: whimsyHero {
              url
              title
            }
            hImageFile {
              childImageSharp {
                fluid(maxWidth: 2600, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  const heroAlt = data.craft.whimsykbd[0].hero[0].title
  const heroImg = data.craft.whimsykbd[0].hImageFile[0]

  const WhimsyHero = styled.section`
    background-color: rgba(240, 240, 240, 1);
    height: 100vh;
    max-width: none;
    padding: 0;
    margin: 0;
    position: relative;

    @media (max-width: 46rem) {
      background-color: rgba(255, 255, 255, 1);
      height: auto;
      display: grid;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      @media (min-width: 46.1rem) {
        position: absolute !important;
      }

      @media (max-width: 46rem) {
        height: 65vh;
        grid-row: 1;

        img {
          object-position: 35% !important;
        }
      }
    }
  `

  const HeroTextContainer = styled.section`
    position: relative;
    z-index: 1;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(12, 1fr);
    gap: 3rem;

    @media (max-width: 46rem) {
      gap: 1rem;
      grid-row: 2;
    }
  `

  const HeroContent = styled.div`
    grid-column: 7 / span 6;
    padding-top: 10rem;

    @media (max-width: 46rem) {
      padding-top: 1rem;
      grid-column: 2 / span 10;
    }
  `

  const WhimsyLeadIn = styled.p`
    margin-bottom: 0;
    font-size: 2.65rem;
    width: 50%;
    font-style: italic;
    font-weight: 300;

    @media (max-width: 46rem) {
      /* font-size: 1.8rem;
      width: 90%; */
      display: none;
    }

    &:after {
      content: "";
      margin: 4rem 0 2rem;
      width: 3rem;
      height: 0.36rem;
      background-color: ${props => props.theme.primaryYellow};
      display: block;
      position: relative;
      left: -1.5rem;
    }
  `

  const WhimsyTitle = styled.h1`
    margin-bottom: 0;
    font-size: 3.95rem;

    @media (max-width: 46rem) {
      padding-top: 1.75rem;
      width: 100%;
      text-align: center;
    }

    &:after {
      content: "";
      margin: 4rem 0 2rem;
      width: 3rem;
      height: 0.36rem;
      background-color: ${props => props.theme.primaryYellow};
      display: block;
      position: relative;
      left: -1.5rem;

      @media (max-width: 46rem) {
        margin: 2rem 0 1rem;
      }
    }
  `

  return (
    <WhimsyHero>
      <HeroTextContainer>
        <HeroContent>
          <WhimsyLeadIn>_a thing that is fanciful or odd</WhimsyLeadIn>
          <WhimsyTitle>Whimsy</WhimsyTitle>
        </HeroContent>
      </HeroTextContainer>
      <Img
        fluid={heroImg.childImageSharp.fluid}
        alt={heroAlt}
        title={heroAlt}
      />
    </WhimsyHero>
  )
}

export default WhimsyHero
