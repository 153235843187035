import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

const WhimsyLegal = () => {
  const data = useStaticQuery(graphql`
    query {
      craft {
        whimsykbd: entries(section: "whimsy") {
          ... on Craft_whimsy_whimsy_Entry {
            whimsyLegal
          }
        }
      }
    }
  `)

  const WhimsyLegal = styled.section`
    max-width: 68rem;
    padding-top: 7rem;
    padding-bottom: 3.5rem;

    h5 {
      font-size: 1.3rem;
      margin: 0 0 2rem 0;
      padding: 0;
      text-align: center;
      width: 100%;
      position: relative;

      &:after {
        content: "";
        width: 2rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.75rem;
        height: 0.22rem;
        background-color: ${props => props.theme.primaryYellow};
      }
    }

    p {
      font-size: 0.78rem;
      text-align: center;
      opacity: 0.7;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  `

  return (
    <WhimsyLegal>
      <h5>Legal &amp; Notices</h5>
      <div
        dangerouslySetInnerHTML={{
          __html: data.craft.whimsykbd[0].whimsyLegal,
        }}
      />
    </WhimsyLegal>
  )
}

export default WhimsyLegal
