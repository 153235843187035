import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    this.props.onClick(this.props.label)
  }

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this

    const AccordionPanel = styled.div`
      &.active {
        .acc--title {
          background-color: rgba(33, 33, 33, 1);
        }
      }
    `

    const AccTitle = styled.div`
      background-color: rgba(100, 100, 100, 1);
      color: rgba(255, 255, 255, 1);
      border-radius: 0.3rem;
      padding: 1rem 1.5rem;
      margin: 0 0 1rem 0;
    `

    const AccContent = styled.div`
      padding: 0.5rem 0 4rem 1.5rem;

      h4 {
        font-size: 1.6rem;
      }

      ul {
        margin: 0 0 0 1rem;
        padding: 0;

        li {
          margin: 0;
          padding: 0;
          font-size: 0.9rem;
        }
      }
    `

    const Arrow = styled.div`
      float: right;
    `

    return (
      <AccordionPanel className={isOpen ? "active" : "not-active"}>
        <AccTitle
          className="acc--title"
          onClick={onClick}
          style={{ cursor: "pointer" }}
        >
          {label}
          <Arrow>
            {!isOpen && <span>&#9650;</span>}
            {isOpen && <span>&#9660;</span>}
          </Arrow>
        </AccTitle>
        {isOpen && <AccContent>{this.props.children}</AccContent>}
      </AccordionPanel>
    )
  }
}

export default AccordionSection
