import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

const WhimsyCurrent = () => {
  const data = useStaticQuery(graphql`
    query {
      craft {
        whimsykbd: entries(section: "whimsy") {
          ... on Craft_whimsy_whimsy_Entry {
            todoList {
              task
              status
            }
            keyDates {
              item
              date
            }
          }
        }
      }
    }
  `)

  const todoList = data.craft.whimsykbd[0].todoList
  const keyDates = data.craft.whimsykbd[0].keyDates

  const WhimsyCurrentContainer = styled.div`
    background: rgb(240, 240, 240);
    padding-top: 6rem;
    padding-bottom: 6rem;
  `

  const WhimsyCurrent = styled.section`
    max-width: 98rem;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(12, 1fr);
    gap: 3rem;

    @media (max-width: 46rem) {
      gap: 1rem;
    }
  `

  const Todo = styled.div`
    grid-column: 3 / span 3;
    text-align: center;

    @media (max-width: 46rem) {
      grid-column-end: span 12;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        &.true {
          position: relative;
          z-index: 0;

          &:after,
          &:before {
            position: absolute;
            content: "";
            top: 50%;
            left: 50%;
            margin: 0;
            padding: 0;
            width: 40%;
            height: 2px;
            background-color: rgba(0, 3, 237, 0.6);
            z-index: 1;
            transform-origin: left;
          }

          &:after {
            transform: rotate(-5deg) translate(-50%, -50%);
          }

          &:before {
            transform: rotate(5deg) translate(-50%, -50%);
          }
        }
      }
    }
  `
  const KeyDates = styled.div`
    grid-column: 7 / span 3;
    text-align: center;

    @media (max-width: 46rem) {
      grid-column-end: span 12;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  `

  return (
    <WhimsyCurrentContainer>
      <WhimsyCurrent>
        <Todo>
          <h3>Todo List</h3>
          <ul>
            {todoList.slice(0, 5).map((todo, i) => (
              <li key={i} className={`${todo.status}`}>
                {todo.task}
                {todo.status}
              </li>
            ))}
          </ul>
        </Todo>

        <KeyDates>
          <h3>Calendar</h3>
          <ul>
            {keyDates.slice(0, 5).map((date, i) => (
              <li key={i} className={`${date.status}`}>
                {date.date} - {date.item}
              </li>
            ))}
          </ul>
        </KeyDates>
      </WhimsyCurrent>
    </WhimsyCurrentContainer>
  )
}

export default WhimsyCurrent
