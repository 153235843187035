import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"

import { getPrettyDate } from "../../utils/dates"
import Accordion from "../site/accordion"

const WhimsyUpdates = () => {
  const data = useStaticQuery(graphql`
    query {
      craft {
        whimsykbd: entries(section: "whimsy") {
          ... on Craft_whimsy_whimsy_Entry {
            whimsyContent {
              __typename
              ... on Craft_whimsyContent_updateDate_BlockType {
                typeHandle
                date: updateDate
                title: updateTitle
                text: updateText
              }
            }
          }
        }
      }
    }
  `)

  const whimsyContent = data.craft.whimsykbd[0].whimsyContent

  const WhimsyUpdates = styled.section`
    padding-top: 6rem;

    h5 {
      font-size: 1.9rem;
      margin: 0 0 2rem 0;
      padding: 0;
      text-align: center;
      width: 100%;
      position: relative;

      &:after {
        content: "";
        width: 2rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.75rem;
        height: 0.22rem;
        background-color: ${props => props.theme.primaryYellow};
      }
    }
  `

  return (
    <WhimsyUpdates>
      <h5>Update Log</h5>
      <Accordion>
        {whimsyContent.map((section, index) => (
          <div
            label={`${getPrettyDate(section.date)} - ${section.title}`}
            key={index}
          >
            <h4>{section.title}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: section.text,
              }}
            />
          </div>
        ))}
      </Accordion>
    </WhimsyUpdates>
  )
}

export default WhimsyUpdates
