import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import SEO from "../components/seo/seo"
import Layout from "../components/layout"

import WhimsyHero from "../components/whimsy/hero"
import WhimsyIntro from "../components/whimsy/intro"
import WhimsyProgress from "../components/whimsy/cta"
import WhimsyRenders from "../components/whimsy/renders"
import WhimsyThanks from "../components/whimsy/thanks"
import WhimsyCurrent from "../components/whimsy/current"
import WhimsyUpdates from "../components/whimsy/updates"
import WhimsyLegal from "../components/whimsy/legal"

const WhimsyPage = () => {
  const data = useStaticQuery(graphql`
    query Whimsy {
      site {
        siteMetadata {
          siteUrl
        }
      }
      craft {
        whimsykbd: entries(section: "whimsy") {
          ... on Craft_whimsy_whimsy_Entry {
            seoTitle
            seoDescription
            image: seoImage {
              title
              url(width: 720, height: 475, quality: 100, immediately: true)
            }
            imageFile {
              childImageSharp {
                fixed(width: 600, quality: 90) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

  const seoTitle = data.craft.whimsykbd[0].seoTitle
  const seoDescription = data.craft.whimsykbd[0].seoDescription
  const seoImage =
    data.site.siteMetadata.siteUrl +
    data.craft.whimsykbd[0].imageFile[0].childImageSharp.fixed.src

  const WhimsyContainer = styled.div`
    margin: 0;
    padding: 0;
  `

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        pathname={`whimsy`}
        website
      />
      <WhimsyContainer>
        <WhimsyHero />
        <WhimsyIntro />
        <WhimsyProgress />
        <WhimsyRenders />
        <WhimsyThanks />
        <WhimsyCurrent />
        <WhimsyUpdates />
        <WhimsyLegal />
      </WhimsyContainer>
    </Layout>
  )
}

export default WhimsyPage
