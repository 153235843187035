import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const WhimsyProgress = () => {
  const CtaContainer = styled.section`
    max-width: 98rem;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(20, 1fr);
    gap: 3rem;
    padding-top: 6rem;
    padding-bottom: 6rem;

    @media (max-width: 46rem) {
      padding-top: 3rem;
      padding-bottom: 3rem;
      gap: 0.25rem;
    }
  `

  const CtaTitle = styled.h4`
    font-size: 1.9rem;
    grid-column-end: span 20;
    text-align: center;
    position: relative;

    &:after {
      content: "";
      width: 2rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -0.75rem;
      height: 0.22rem;
      background-color: ${props => props.theme.primaryYellow};
    }
  `

  const CtaContent = styled.div`
    grid-column-end: span 20;
    text-align: center;

    p {
      width: 60%;
      margin: 0 auto;

      @media (max-width: 46rem) {
        width: 100%;
      }

      &:first-of-type {
        margin-bottom: 1.3rem;
      }
    }
  `

  const Cta = styled.div`
    grid-column-end: span 4;

    @media (max-width: 46rem) {
      grid-column-end: span 20;
      text-align: center;
    }
  `

  return (
    <CtaContainer>
      <CtaTitle>Keep up to date with Whimsy progress...</CtaTitle>
      <CtaContent>
        <p>
          If you have any interest in Whimsy at this stage I would genuinely
          love to hear your thoughts/feedback. If you've got a couple of minutes
          spare to fill out the interest form linked below, i would greatly
          appreciate it.
        </p>
        <p>
          The IC is due to launch on GeekHack and Reddit sometime in the next
          couple of weeks so look out for that.. in the meantime, please feel
          free to contact me via email, Twitter, Reddit or Instagram and I’ll
          aim to get back to you with more details as and when I have them.
        </p>
      </CtaContent>
      <Cta>
        <a
          href="https://www.reddit.com/r/MechanicalKeyboards/comments/fr7w4o/ic_whimsy_40_keyboard/"
          title="Whimsy IC on Reddit"
        >
          Reddit IC
        </a>
      </Cta>
      <Cta>
        <a
          href="https://geekhack.org/index.php?topic=105358.0"
          title="Whimsy IC on GeekHack"
        >
          GeekHack IC
        </a>
      </Cta>
      <Cta>
        <a href="https://forms.gle/hQ3SqLB3EJPA5gsM9" title="Whimsy IC Form">
          Interest Form
        </a>
      </Cta>
      <Cta>
        <a
          href="https://instagram.com/whimsykbd"
          title="Whimsy IC on Instagram"
        >
          Whimsy Instagram
        </a>
      </Cta>
      <Cta>
        <a href="https://discord.gg/saDb87b" title="Whimsy Discord">
          Whimsy Discord
        </a>
      </Cta>
    </CtaContainer>
  )
}

export default WhimsyProgress
